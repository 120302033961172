










import {
  ref,
  useContext,
  useFetch,
  useRoute,
  defineComponent, onMounted, watch
} from '@nuxtjs/composition-api';
import { init } from 'dc-visualization-sdk';
import IconSprite from '~/components/General/IconSprite.vue';

export default defineComponent({
  name: 'Visualisation',
  components: {
    IconSprite,
  },
  layout: 'visualisation',
  setup() {
    const route = useRoute();
    const { app } = useContext();
    const page = ref(null);
    const key = ref<number>(1);

    useFetch(async () => {
      if (route.value.query.content) {
        const [content] = await app.$vsf.$ampl.api.getContent({
          id: route.value.query.content,
          preview: `https://${route.value.query.api}`,
        });
        page.value = content;
      }
    });

    // We need this hack in order to update component rendering
    watch(page, () => key.value += 1);

    onMounted(async () => {
      const sdk = await init();
      sdk.form.changed((model) => page.value = model.content);
    });

    return {
      page,
      key,
    };
  },
});
